// initial load

function init() {
  var $els = document.querySelectorAll(".color-choose input");
  $els.forEach(($el) =>
    $el.addEventListener("click", function () {
      var color = this.getAttribute("data-image");
      document.querySelectorAll(".active").forEach(function (el) {
        el.classList.remove("active");
      });
      document
        .querySelector(".left-column img[data-image = " + color + "]")
        .classList.add("active");
      this.classList.add("active");
    })
  );
}

if (document.readyState === "complete") {
  init();
} else {
  window.addEventListener("load", init);
}
